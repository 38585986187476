.scrollbar-style .scroll-box-thumb.horizontal {
  background-color: gray;
  height: 10px;
  border-radius: 0%;
  visibility: visible;
}

.scrollbar-style .scroll-box-thumb.vertical {
  background-color: gray;
  height: 10px;
  border-radius: 0%;
  visibility: visible;
}

.scrollbar-style .scroll-box-track.horizontal {
  background-color: transparent;
}
